import observationService from '@/services/observationService.js'
import obsTypeConverter from '@/scripts/obsTypeConverter.js'
import { StudyplotService } from '@/services/StudyplotService'
import { deepClone } from '@/scripts/common'
import { SNOWPROFILE } from '@/scripts/const'

export const commonReportObsBuilderMixin = {
  computed: {},
  methods: {
    getCommonObservationPromise(item) {
      if (item.type.startsWith(SNOWPROFILE)) {
        return undefined
      }
      // Ist für die Ausnahmen zuständig ==> (Beispiel: ASSESSMENT_DECISION_AREA = ASSESSMENT_DECISION)
      const commonType = obsTypeConverter.getCommonTypeFromSpecialType(
        item.type
      )
      const subObject = item[commonType]
      let obsPromise
      if (typeof subObject === 'object' && subObject !== null) {
        // Vorhandenes Objekt als Deep-Klon wiederverwenden
        obsPromise = new Promise((resolve) => {
          resolve(this.getStudyplotLocation(deepClone(item)))
        })
      } else {
        // Objekt muss neu geladen werden
        obsPromise = this.getCommonObsPromise(item.id)
      }
      return obsPromise.then((obsClone) => {
        return obsClone
      })
    },
    getCommonObsPromise(id) {
      const vm = this
      return new Promise((resolve, reject) => {
        observationService
          .getObservationById(id)
          .then((obs) => {
            resolve(vm.buildCommonReportObs(obs))
          })
          .catch((err) => {
            const errorMsg =
              "observation with id '" +
              id +
              "' could not be loaded (" +
              err +
              ')'
            console.error(errorMsg)
            reject(errorMsg)
          })
      })
    },
    buildCommonReportObs(obsResult) {
      return this.flattenHierarchyData(obsResult.data.data)
    },
    flattenHierarchyData(obsData) {
      if (obsData && obsData.hierarchy) {
        obsData.areaId = obsData.hierarchy.areaId
        obsData.avalServiceId = obsData.hierarchy.avalServiceId
        obsData.avalanchePathId = obsData.hierarchy.avalanchePathId
        obsData.objectId = obsData.hierarchy.objectId
        obsData.sectorId = obsData.hierarchy.sectorId
      }
      return obsData
    },
    getStudyplotLocation(obsClone) {
      if (obsClone && obsClone.measurement) {
        const code = obsClone.measurement.studyplot
        if (this.$store.state.profile && this.$store.state.profile.studyplots) {
          return StudyplotService.getStudyplotByCode(code)
            .then((data) => {
              if (!obsClone.location) {
                obsClone.location = data.location
              }
              return obsClone
            })
            .catch((e) => {
              const errorMsg = 'Could not find studyplot :' + e
              console.error(errorMsg)
              throw errorMsg
            })
        }
      }
      return new Promise((resolve) => {
        resolve(obsClone)
      })
    },
  },
}
